import React, { Component } from "react";
import Timer from "./components/timer/timer";
import Photos from "./components/photos/photos";
import Timeline from "./components/timeline/timeline";
import Camera from "./components/camera/camera";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import "./css/general.css";

const categories = ["trouw", "shoot", "receptie", "feest"];

class Home extends Component {
  render() {
    return (
      <div>
        <div
          className="text-center"
          style={{ fontSize: "1.3em", lineHeight: "1em" }}
        >
          <p>
            Via de menu-items hierboven kan je onze dag herbeleven.
            <br />
            Van welk moment zou je de foto's willen bekijken?
          </p>
          <ul style={{ margin: 0, padding: 0 }}>
            {categories.map(category => (
              <li
                key={category}
                style={{
                  textAlign: "center",
                  display: "block",
                  padding: 0,
                  margin: 0
                }}
              >
                <NavLink to={"/photos/" + category} data-info={category}>
                  {category}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="text-center">
          <Timer />
          <Timeline />
        </div>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <div className="container">
          <header className="clearfix">
            <a class="title" href="/"><h1>
              Huwelijk <span className="nobreak">Toon en Emilie</span>
              <span id="subtitle" />
            </h1></a>
            <nav id="nav">
              <NavLink
                to={"/"}
                className={"button"}
                activeClassName={"selected"}
                isActive={(match, location) =>
                  location && location.pathname === "/"
                }
                data-info={"home"}
              />
              <NavLink
                to={"/camera"}
                className={"button"}
                activeClassName={"selected"}
                data-info={"camera"}
              />
              {categories.map(category => (
                <NavLink
                  key={category}
                  to={"/photos/" + category}
                  className={"button"}
                  activeClassName={"selected"}
                  data-info={category}
                />
              ))}
            </nav>
          </header>
          <Route path="/" exact component={Home} />
          <Route path="/photos/:category" component={Photos} />
          <Route path="/camera" component={Camera} />
        </div>
      </Router>
    );
  }
}

export default App;
