import React from "react";
import ImageUploader from "react-images-upload";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";

const uploadable = false;

export default class extends React.Component {
  state = { pictures: null, images: [], currentImage: 1 };
  fileReader = new FileReader();

  constructor(...args) {
    super(...args);

    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.fileReader.addEventListener("load", () => {
      if (this.img) this.img.src = this.fileReader.result;
    });
  }

  fetchImages() {
    fetch("/camera-upload/index.php?list")
      .then(response => response.json())
      .then(images => this.setState({ images }))
      .catch(error => console.error(error));
  }

  upload() {
    this.setState({ loading: true });
    const picture = this.state.pictures[0];
    const data = new FormData();
    data.append("files[]", picture);
    fetch("/camera-upload/index.php", {
      method: "POST",
      body: data
    })
      .then(response => {
        if (response.status !== 200) console.error(response);
        else {
          this.setState({
            pictures: null,
            loading: false
          });
          this.fetchImages();
        }
      })
      .catch(error => console.error(error));
  }

  componentDidMount() {
    this.fetchImages();
  }

  render() {
    const { pictures, images, loading } = this.state;
    if (pictures) this.fileReader.readAsDataURL(pictures[0]);

    return (
      <div>
        {uploadable ? (
          pictures ? (
            <div>
              <img
                ref={img => (this.img = img)}
                alt="to upload"
                style={{
                  maxWidth: "100%",
                  maxHeight: "80vh",
                  display: "block",
                  margin: "auto",
                  opacity: loading ? 0.4 : 1
                }}
              />
              <div style={{ textAlign: "center", margin: ".6em 0" }}>
                <button
                  onClick={() => this.upload()}
                  className="btn btn-primary"
                >
                  Upload
                </button>{" "}
                <button
                  onClick={() =>
                    this.setState({
                      pictures: null,
                      loading: false
                    })
                  }
                  className="btn btn-link"
                >
                  Annuleer
                </button>
              </div>
            </div>
          ) : (
            <ImageUploader
              withIcon={false}
              buttonText="Neem een foto!"
              withPreview={false}
              onChange={pictures => this.setState({ pictures })}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              singleImage={true}
              maxFileSize={10 * 1024 * 1024}
            />
          )
        ) : null}
        {images.length ? (
          <Gallery
            direction={"column"}
            photos={images}
            onClick={this.openLightbox}
          />
        ) : null}
        {images.length ? (
          <Lightbox
            images={images}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        ) : null}
      </div>
    );
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
}
