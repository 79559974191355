import React, { Component } from "react";
import rawData from "./data.json";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import moment from "moment";
import "moment/locale/nl";
import { transformer } from "d3-scale/src/continuous";
import { MapInteraction } from "../../lib/react-map-interaction";

moment.locale("nl");

const parseDate = (d) => {
  if (d === null) return d;
  const date = new Date(d);
  return isNaN(date) ? null : date;
};

const events = rawData.map(({ date, until, ...rest }) => {
  return { date: parseDate(date), until: parseDate(until), ...rest };
});
events.sort(({ date: a }, { date: b }) => a - b);

const day = 1000 * 60 * 60 * 24;
const minDate = +new Date(2007, 1, 1);
const maxDate = Math.max(...events.map(({ date }) => date)) + 30 * day;
const zoomLevels = [
  {
    view: maxDate - minDate,
    nextTick: (d) => new Date(d.getFullYear() + 1, 0, 1),
    formatTick: (d) =>
      d.getFullYear() % 2 === 0 ? "" + d.getFullYear() : null,
  },
  {
    view: 6 * 365 * day,
    nextTick: (d) => new Date(d.getFullYear() + 1, 0, 1),
    formatTick: (d) => "" + d.getFullYear(),
  },
  {
    view: 2 * 365 * day,
    nextTick: (d) => new Date(d.getFullYear(), d.getMonth() + 1, 1),
    formatTick: (d) => {
      return d.getMonth() % 4 === 0 ? moment(d).format("MMMM YYYY") : null;
    },
  },
  {
    view: 90 * day,
    nextTick: (d) => new Date(d.getFullYear(), d.getMonth() + 1, 1),
    formatTick: (d) => moment(d).format("MMMM YYYY"),
  },
];

const format = (t) => {
  return moment(t).format("Do MMM YY");
};
const clamp = (a, b, c) => {
  return b < a ? a : b > c ? c : b;
};

class TimelineChart extends Component {
  renderTooltip(props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;

      return (
        <div
          className="card"
          style={{
            maxWidth: "80vw",
          }}
        >
          <div className="card-body" style={{ maxWidth: "400px" }}>
            <h3 className="card-title">{data.event}</h3>
            <h5 className="card-subtitle mb-2 text-muted">
              {format(data.date)}
            </h5>
            <p className="card-text">{data.info}</p>
          </div>
        </div>
      );
    }
    return null;
  }
  renderShape(props) {
    if (isNaN(props.cx) || isNaN(props.cy)) return;
    const r = props.icon ? 15 : 7;
    const attributes = {
      cx: props.cx,
      cy: props.cy,
      fill: props.color,
    };
    const icon = props.icon ? (
      <text
        x={attributes.cx}
        y={attributes.cy}
        textAnchor="middle"
        alignmentBaseline="central"
        fill="#fff"
        className="material-icons"
      >
        {props.icon}
      </text>
    ) : null;
    const xAxis = (this.xAxis = props.xAxis);
    return (
      <g>
        {props.until ? (
          <ellipse
            {...attributes}
            ry={r}
            rx={r + 0.5 * (xAxis.scale(props.until) - xAxis.scale(props.date))}
          />
        ) : (
          <circle {...attributes} r={r} />
        )}
        {icon}
      </g>
    );
  }

  render() {
    const e = 1.6;

    const zoomStart = this.props.xPan;
    const zoomEnd = this.props.xPan + 1 / this.props.scale;

    const invTransform = (y) => {
      const w = zoomStart + (zoomEnd - zoomStart) * y;
      const v = Math.pow(w, 1 / e);
      return minDate + v * (maxDate - minDate);
    };

    const xScale = transformer()((x) => {
      const v = clamp(0, (x - minDate) / (maxDate - minDate), +Infinity);
      return (Math.pow(v, e) - zoomStart) / (zoomEnd - zoomStart);
    }, invTransform);

    const dateStart = invTransform(0);
    const dateEnd = invTransform(1);

    const zoomView = dateEnd - dateStart;
    let zoomLevel = zoomLevels[0];
    for (let i = 1; i < zoomLevels.length; ++i) {
      if (
        Math.abs(1 - zoomView / zoomLevel.view) >
        Math.abs(1 - zoomView / zoomLevels[i].view)
      )
        zoomLevel = zoomLevels[i];
    }
    const { nextTick, formatTick } = zoomLevel;

    const data = events
      .filter(({ date }) => date && date >= dateStart && date <= dateEnd)
      .map(({ date, until, ...rest }) => {
        return {
          anchor: until ? 0.5 * (+date + +until) : +date,
          date: +date,
          until: until ? +until : null,
          ...rest,
        };
      });
    const ticks = [];
    let d = nextTick(new Date(dateStart));
    while (+d <= dateEnd) {
      ticks.push(+d);
      d = nextTick(d);
    }

    return (
      <ResponsiveContainer>
        <ScatterChart
          margin={{
            top: 0,
            right: 40,
            bottom: 0,
            left: 0,
          }}
          data={data}
        >
          <XAxis
            ref={(r) => (this.XAxis = r)}
            type="number"
            dataKey="anchor"
            domain={[dateStart, dateEnd]}
            ticks={ticks}
            interval={0}
            scale={xScale}
            tick={({ x, y, stroke, payload }) => {
              const s = formatTick(new Date(payload.value));
              if (!s) return null;
              return (
                <g transform={`translate(${x},${y})`}>
                  <text y={7} x={5} textAnchor="start">
                    {s}
                  </text>
                </g>
              );
            }}
          />
          <YAxis
            type="number"
            dataKey="yValue"
            domain={[-0.3, 1.2]}
            width={40}
            height={10}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
            content={this.renderTooltip}
          />
          <Scatter
            isAnimationActive={false}
            fill="#8884d8"
            shape={this.renderShape.bind(this)}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
}

class Timeline extends Component {
  state = {
    width: 0,
  };

  updateWidth() {
    const { width } = this.container
      ? this.container.getBoundingClientRect()
      : 0;
    if (this.state.width !== width) this.setState({ width });
  }

  render() {
    const width = this.state.width;
    let xOffset = 0;
    return (
      <div
        style={{ width: "100%", marginTop: 60, marginBottom: 60 }}
        className="timeline"
      >
        <h2>Een overzicht van onze geschiedenis</h2>
        <div
          style={{ height: "200px", marginTop: -20 }}
          ref={(node) => {
            this.container = node;
            if (node) {
              this.updateWidth();
            }
          }}
        >
          {this.state.width > 0 && (
            <MapInteraction
              ref={(mi) => {
                console.log((window.mi = mi));
              }}
              minScale={1}
              maxScale={30}
            >
              {({ translation: { x: xPan }, scale }) => {
                let x = xOffset - xPan / scale / width;

                if (x < 0) {
                  xOffset = xPan / scale / width;
                  x = 0;
                } else if (x > 1 - 1 / scale) {
                  x = 1 - 1 / scale;
                  xOffset = xPan / scale / width + (1 - 1 / scale);
                }

                return <TimelineChart xPan={x} scale={scale} />;
              }}
            </MapInteraction>
          )}
        </div>
      </div>
    );
  }
}

export default Timeline;
