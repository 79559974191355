import React, { Component } from "react";
import "./timer.scss";

const mariage = new Date(2019, 4, 24, 15, 45);
const seconds = 1 / 1000,
  minutes = seconds / 60,
  hours = minutes / 60,
  days = hours / 24,
  years = seconds / 31557600,
  months = years * 12;

class Timer extends Component {
  state = {
    now: new Date(),
  };
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ now: new Date() }), 100);
  }
  render() {
    const until = mariage - this.state.now;

    const format = (n) => {
      let s = "" + n;
      let t = "";
      for (let i = 1; i <= s.length; ++i) {
        t = s[s.length - i] + t;
        if (i % 3 === 0) t = " " + t;
      }
      return t;
    };

    const plural = (time, single, plur) => {
      time = Math.floor(Math.abs(time));
      return (
        <li>
          <span className="number">{format(time)}</span>{" "}
          {time === 1 ? single : plur}
        </li>
      );
    };

    return (
      <div className="timer">
        <h2>{until > 0 ? "Wij trouwen over" : "Wij zijn getrouwd! Al voor"}</h2>
        <ul>
          {plural(until * seconds, "seconde", "seconden")}
          {plural(until * minutes, "minuut", "minuten")}
          {plural(until * hours, "uur", "uren")}
          {plural(until * days, "dag", "dagen")}
          {plural(until * months, "maand", "maanden")}
          {plural(until * years, "jaar", "jaren")}
        </ul>
      </div>
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

export default Timer;
